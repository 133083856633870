import { render, staticRenderFns } from "./v-phone-field.vue?vue&type=template&id=6a606703&scoped=true"
import script from "./v-phone-field.vue?vue&type=script&lang=js"
export * from "./v-phone-field.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a606703",
  null
  
)

export default component.exports