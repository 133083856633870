import { render, staticRenderFns } from "./v-email-field.vue?vue&type=template&id=28def920&scoped=true"
import script from "./v-email-field.vue?vue&type=script&lang=js"
export * from "./v-email-field.vue?vue&type=script&lang=js"
import style0 from "./v-email-field.vue?vue&type=style&index=0&id=28def920&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28def920",
  null
  
)

export default component.exports